#message {
  color: #000000de;
  background: #fff;
  border-radius: 3px;
  max-width: 360px;
  margin: 100px auto 16px;
  padding: 32px 24px 16px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

#message h3 {
  color: #888;
  margin: 16px 0 12px;
  font-size: 16px;
  font-weight: normal;
}

#message h1 {
  color: #0009;
  margin: 0 0 16px;
  font-size: 22px;
  font-weight: 300;
}

#message p {
  margin: 16px 0 24px;
  font-size: 14px;
  line-height: 140%;
}

#message a {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background: #039be5;
  border-radius: 4px;
  padding: 16px;
  text-decoration: none;
  display: block;
}

#message, #message a {
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

#load {
  color: #0006;
  text-align: center;
  font-size: 13px;
}

@media (width <= 600px) {
  #message {
    box-shadow: none;
    background: #fff;
    border-top: 16px solid #ffa100;
    margin-top: 0;
  }

  #message h2 {
    color: #ffa100;
    margin: 0 0 8px;
    font-size: 16px;
    font-weight: bold;
  }
}
/*# sourceMappingURL=index.dd4aed2c.css.map */
